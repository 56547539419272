const api = {
  Login: '/ly_user_center/sso/login',
  Logout: '/auth/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  twoStepCode: '/auth/2step-code',
  SendSms: '/ly_user_center/sso/sendValidateCode',
  initRandomCode: '/ly_user_center/sso/initRandomCode',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/ly_user_center/user/userInfo'
}
export default api

/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { constantRouterMap } from '@/config/router.config'
import { generatorDynamicRouter } from '@/router/generator-routers'

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    actions: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_ACTIONS: (state, actions) => {
      state.actions = actions
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        const { token } = {}
        generatorDynamicRouter(token).then((routers) => {
          commit('SET_ROUTERS', routers.result1)
          commit('SET_ACTIONS', routers.result2)
          resolve()
        })
      })
    }
  }
}

export default permission

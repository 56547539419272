// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import { VueAxios } from './utils/request'

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control
import './utils/filter' // global filter
import './components/global.less'
import {Tree,Upload} from 'ant-design-vue'
import VueCookies from 'vue-cookies'
Vue.prototype.domain= process.env.VUE_APP_DOMAIN;
Vue.prototype.ossbasepath= process.env.VUE_APP_OSSBASEPATH;
Vue.config.productionTip = false

// mount axios Vue.$http and this.$http
Vue.use(VueAxios)
Vue.use(Tree)
Vue.use(Upload);
Vue.use(VueCookies)
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    })
  }
});
new Vue({
  router,
  store,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
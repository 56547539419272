<template>
  <transition name="showHeader">
    <div v-if="visible" class="header-animat">
      <a-layout-header
        v-if="visible"
        :class="[
          fixedHeader && 'ant-header-fixedHeader',
          sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed',
        ]"
        :style="{ padding: '0' }"
      >
        <div v-if="mode === 'sidemenu'" class="header">
          <div style="float: left">
            <a-icon
              v-if="device === 'mobile'"
              class="trigger"
              :type="collapsed ? 'menu-fold' : 'menu-unfold'"
              @click="toggle"
            />
            <a-icon v-else class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggle" />
            {{ orgName }}
          </div>
          <div style="width: 45vw; height: 64px; float: left; padding-left: 140px">
            <div class="merquee">
              <span class="merquee-txt" id="merquee-txt">{{ noticeMes }}</span>
            </div>
          </div>
          <user-menu></user-menu>
        </div>
        <div v-else :class="['top-nav-header-index', theme]">
          <div class="header-index-wide">
            <div class="header-index-left">
              <logo class="top-nav-header" :show-title="device !== 'mobile'" />
              <s-menu v-if="device !== 'mobile'" mode="horizontal" :menu="menus" :theme="theme" />
              <a-icon v-else class="trigger" :type="collapsed ? 'menu-fold' : 'menu-unfold'" @click="toggle" />
            </div>
            <user-menu class="header-index-right"></user-menu>
          </div>
        </div>
      </a-layout-header>
    </div>
  </transition>
</template>

<script>
import UserMenu from '../tools/UserMenu'
import SMenu from '../Menu/'
import Logo from '../tools/Logo'
import { mixin } from '@/utils/mixin'
import { Carousel } from 'ant-design-vue'
import axios from 'axios'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  name: 'GlobalHeader',
  components: {
    UserMenu,
    SMenu,
    Logo,
    Carousel,
  },
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      // sidemenu, topmenu
      default: 'sidemenu',
    },
    menus: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: 'dark',
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    device: {
      type: String,
      required: false,
      default: 'desktop',
    },
  },
  data() {
    return {
      visible: true,
      oldScrollTop: 0,
      dotPosition: 'top',
      notice: [],
      noticeShow: false,
      timer: null,
      visible2: true,
      noticeList:[],
      noticeMes:'',
      orgName:"cssfwfwewee"
    }
  },
  mounted() {
    let that = this
    this.orgName = Vue.ls.get("orgName")
    document.addEventListener('scroll', this.handleScroll, { passive: true })
    clearInterval(this.timer)
    that.loadmes();
    this.timer = setInterval(() => {
      that.loadmes();
    }, 300000)
  },
  methods: {
    handleScroll() {
      if (!this.autoHideHeader) {
        return
      }

      const scrollTop = document.body.scrollTop + document.documentElement.scrollTop
      if (!this.ticking) {
        this.ticking = true
        requestAnimationFrame(() => {
          if (this.oldScrollTop > scrollTop) {
            this.visible = true
          } else if (scrollTop > 300 && this.visible) {
            this.visible = false
          } else if (scrollTop < 300 && !this.visible) {
            this.visible = true
          }
          this.oldScrollTop = scrollTop
          this.ticking = false
        })
      }
    },
    toggle() {
      this.$emit('toggle')
    },
    loadmes(){
      let that = this
      let token = Vue.ls.get(ACCESS_TOKEN)
      axios({
        url: "/service_provider/notice/findList",
        method: 'post',
        headers: {
          'token':token,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {recordState:1,onlineState:1,type:30}
      }).then(res =>{
        if(res!=null&&res.data!=null&&res.data.result!=null){
          that.noticeMes = ""
          that.noticeList = res.data.result
          for(let i in that.noticeList){
            that.noticeMes += that.noticeList[i].content+"        "
          }
          document.getElementById("merquee-txt").innerText=that.noticeMes
        }
      })
    },
    flushorgName(){
      this.orgName = Vue.ls.get("orgName")
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.handleScroll, true)
  },
}
</script>

<style lang="less">
@import '../index.less';

.header-animat {
  position: relative;
  z-index: @ant-global-header-zindex;
}
.showHeader-enter-active {
  transition: all 0.25s ease;
}
.showHeader-leave-active {
  transition: all 0.5s ease;
}
.showHeader-enter,
.showHeader-leave-to {
  opacity: 0;
}
</style>

<style lang="less" scoped>
.ant-carousel {
  flex: 1;
  max-width: 60%;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}


/* 文字滚动 */
.merquee {
  height: 64px;
  line-height: 64px;
  box-sizing: border-box;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
.merquee-txt {
  display: inline-block;
  padding-left: 100%;
  /* 从右至左开始滚动 */
  animation: marqueeTransform 16s linear infinite;
}

@keyframes marqueeTransform {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
</style>

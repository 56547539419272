<template>
  <div class="user-wrapper">
    <div class="content-box">
<!--      <a href="https://pro.loacg.com/docs/getting-started" target="_blank">-->
<!--        <span class="action">-->
<!--          <a-icon type="question-circle-o"></a-icon>-->
<!--        </span>-->
<!--      </a>-->
      <notice-icon class="action"/>
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" size="large" src="avatar.png" > </a-avatar>
          <span>{{ nickname }}</span>
        </span>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
<!--          <a-menu-item key="0">-->
<!--            <router-link :to="{ name: 'center' }">-->
<!--              <a-icon type="user"/>-->
<!--              <span>个人中心</span>-->
<!--            </router-link>-->
<!--          </a-menu-item>-->
<!--          <a-menu-item key="1">-->
<!--            <router-link :to="{ name: 'settings' }">-->
<!--              <a-icon type="setting"/>-->
<!--              <span>账户设置</span>-->
<!--            </router-link>-->
<!--          </a-menu-item>-->
<!--          <a-menu-item key="2" disabled>-->
<!--            <a-icon type="setting"/>-->
<!--            <span>测试</span>-->
<!--          </a-menu-item>-->

          <a-menu-item key="0">
            <a href="javascript:;" @click="onChangeOrg">
              <a-icon type="user"/>
              <span>切换企业</span>
            </a>
          </a-menu-item>

          <a-menu-item key="1">
            <router-link :to="{ name: 'center' }">
              <a-icon type="user"/>
              <span>个人中心</span>
            </router-link>
          </a-menu-item>

          <a-menu-divider/>
          <a-menu-item key="3">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout"/>
              <span>退出登录</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>

    <a-modal
      title="请选择登录企业"
      :width="540"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="changeOrgHandleCancel"
      :centered="true"
      :footer="null"
      :mask="true"
      :maskClosable="false"
      :body-style="{height:'300px'}"
    >
      <a-select defaultValue="请选择服务商" style="width: 320px;margin:40px 0 0 85px;" @change="handleChange">
        <a-select-option v-for="(item, index) in orgsList" :key="index" :value="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-modal>
<!--    <a-popconfirm-->
<!--      placement="bottom"-->
<!--      :title="text"-->
<!--      :autoAdjustOverflow=false-->
<!--      :visible="visible2"-->
<!--      ok-text="前往"-->
<!--      cancel-text="稍后处理"-->
<!--      @visibleChange="handleVisibleChange"-->
<!--      @confirm="changeOrgHandleConfirm"-->
<!--      @cancel="changeOrgHandleCancel"-->
<!--    >-->
<!--    </a-popconfirm>-->
  </div>

</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import GlobalHeader from "@/components/GlobalHeader/GlobalHeader";
import { mapActions, mapGetters } from 'vuex'
import Vue, { h } from 'vue';
import {timeFix} from "@/utils/util";
export default {
  // name: 'UserMenu',
  components: {
    NoticeIcon,
    GlobalHeader
  },
  data () {
    return {
      visible:false,
      orgsList:null,
      confirmLoading:false,
      visible2:true,
    }
  },
  computed: {
    ...mapGetters(['nickname', 'avatar'])

  },
  methods: {
    ...mapActions(['Logout','ChangeOrg','FindOrgs']),
    handleLogout () {
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk: () => {
          return this.Logout({}).then(() => {
            setTimeout(() => {
              localStorage.removeItem('autoLoginCode')
              //this.$cookies.remove('autoLoginCode');
              this.$router.push({ path: '/user/login' })
            }, 16)
          }).catch(err => {
            this.$message.error({
              title: '错误',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
    },
    onChangeOrg () {
      console.info("---------")
      let that = this;
      //加载该用户所关联的企业列表
      const {
        FindOrgs
      } = this
      let optionList = [];
      FindOrgs().then((res) => {
        that.orgsList = res
        this.visible = true
      })
    },
    changeOrgHandleCancel () {
      this.visible = false

    },
    handleChange (value) {
      console.log(`selected ${value}`);
      let that = this;
      const {
        ChangeOrg
      } = this
      ChangeOrg({id:value}).then((res) => {
        //that.$router.push({ path: '/' })
        Vue.ls.set("orgName", res.orgName)
        Vue.ls.set("csId", res.csId)
        Vue.ls.set("csQrcode", res.csQrcode)
        Vue.ls.set("csTel", res.csTel)
        window.location.reload();
        setTimeout(() => {
          that.$notification.success({
            message: '欢迎',
            description: `${timeFix()}，欢迎回来`
          })
        }, 1000)
        that.isLoginError = false
        that.visible = false
      })
    },
  },


}
</script>

<style lang="less" scoped>
.avatar{
  background: #364d79;
}
</style>
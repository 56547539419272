<template>
  <div class="logo" >
    <router-link :to="{ name: 'dashboard' }">
      <LogoSvg alt="logo" />
      <h1 v-if="showTitle">{{ title }}</h1>
    </router-link>
  </div>
</template>

<script>
import LogoSvg from '@/assets/logo2.svg?inline'

export default {
  name: 'Logo',
  components: {
    LogoSvg,
  },
  props: {
    title: {
      type: String,
      default: '冰鸽冷链服务商',
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
}
</script>
<style lang="less" >

</style>

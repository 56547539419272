// eslint-disable-next-line
import * as loginService from '@/api/login'
// eslint-disable-next-line
import { BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'
import router from '@/router'
import NProgress from "nprogress";
import Vue from 'vue'
import { ACCESS_TOKEN } from "@/store/mutation-types";
// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,
  '403': () => import(/* webpackChunkName: "error" */ '@/views/exception/403'),
  '404': () => import(/* webpackChunkName: "error" */ '@/views/exception/404'),
  '502': () => import(/* webpackChunkName: "error" */ '@/views/exception/502'),
  '500': () => import(/* webpackChunkName: "error" */ '@/views/exception/500'),
  'RoleView': () => import('@/views/role/RoleList'),//角色列表
  'UserView': () => import('@/views/user/UserList'),//用户列表
  'Workplace': () => import('@/views/dashboard/Workplace'),//工作台
  'AccountCenter': () => import('@/views/center/UserCenter'),//个人中心
  'WaitingOrderView': () => import('@/views/message/WaitingOrder'),//待接单
  'FollowUpView': () => import('@/views/message/FollowUp'),//跟进事项
  'SysNoticeView': () => import('@/views/message/SysNotice'),//中心平台通知
  'SysMessageView': () => import('@/views/message/SysPlatformMessage'),//中心平台消息
  'WarehouseView': () => import('@/views/source/Warehouse'),//仓库管理
  'QyCustomerView': () => import('@/views/customer/QyCustomer'),//企业客户
  'HyCustomerView': () => import('@/views/customer/HyCustomer'),//合约客户
  'LessLoadView': () => import('@/views/dispatch/LoadDispatch'),//提货调度
  'LessTransportView': () => import('@/views/dispatch/LessTransport'),//提货调度
  'InStorageView': () => import('@/views/dispatch/InStorage'),//入库管理
  'changeWarehouseView': () => import('@/views/dispatch/ChangeWarehouse'),//入库管理
  'TltTransportView': () => import('@/views/dispatch/TltTransport'),//整车调度
  'DriverView': () => import('@/views/source/Driver'),//司机管理
  'VehicleView': () => import('@/views/source/Vehicle'),//车辆管理

  'scatteredCost': () => import('@/views/quotation/scatteredCost'),
  'costInstructions': () => import('@/views/quotation/costInstructions'),
  'scatteredProfit': () => import('@/views/quotation/scatteredProfit'),
  'companyCustomer': () => import('@/views/quotation/companyCustomer'),
  'contractCustomer': () => import('@/views/quotation/contractCustomer'),
  'outletsCustomer': () => import('@/views/quotation/outletsCustomer'),
  'offerTable': () => import('@/views/quotation/offerTable'),
  'sectionPrice': () => import('@/views/quotation/sectionPrice'),
  'offerRemark': () => import('@/views/quotation/offerRemark'),
  'fixedPrice': () => import('@/views/quotation/fixedPrice'),
  'arteryManage': () => import('@/views/quotation/arteryManage'),
  'transportFirst': () => import('@/views/quotation/transportFirst'),
  'transportSecond': () => import('@/views/quotation/transportSecond'),
  'dispatchFee': () => import('@/views/quotation/dispatchFee'),
  'pickupFee': () => import('@/views/quotation/pickupFee'),
  'lowestPrice': () => import('@/views/quotation/lowestPrice'),
  'deliveryFee': () => import('@/views/quotation/deliveryFee'),//交仓


  'orderTaking': () => import('@/views/serve/OrderTaking'),
  'secondaryOrderConfirm': () => import('@/views/serve/SecondaryOrderConfirm'),
  'orderDetail': () => import('@/views/serve/OrderDetail'),
  'OrderDetailDisView': () => import('@/views/serve/OrderDetail'),
  'OrderDetailSecondView': () => import('@/views/serve/OrderDetail'),
  'onlineDisperseOrder': () => import('@/views/serve/OnlineDisperseOrder'),
  'onlineWholeOrderSingle': () => import('@/views/serve/WholeCarOrder'),
  'onlineWholeOrderMuti': () => import('@/views/serve/WholeCarMutiOrder'),
  'onlineWholeOrderOne': () => import('@/views/serve/WholeCardOneOrder'),
  'orderManager': () => import('@/views/serve/OrderManager'),
  'LeveljudgeView': () => import('@/views/organization/leveljudge'),
  'UnserverAreaView': () => import('@/views/unserver/UnserverArea'),
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*', redirect: '/404', hidden: true, name: '404'
}

// 根级菜单
const rootRouter = {
  key: '',
  name: 'BasicLayout',
  path: '',
  component: 'BasicLayout',
  redirect: '/dashboard/workplace',
  meta: {
    title: '首页'
  },
  children: []
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = (token) => {
  return new Promise((resolve, reject) => {
    loginService.getCurrentUserNav().then(res => {
      console.log('res', res)
      const { result } = res

      const menuNav = []
      const childrenNav = []
      const result2 = []
      const result3 = []
      const resultAll = {}
      menuNav.push(rootRouter)

      // 区分菜单和按钮权限
      result.forEach(item => {
        if (item.type === 1) {
          result2.push(item)
          menuNav.push(item)
        } else {
          result3.push(item)
        }
      })
      //      后端数据, 根级树数组,  根级 PID
      listToTree(result2, childrenNav, 0)
      rootRouter.children = childrenNav
      console.log('menuNav', menuNav)
      const routers = generator(menuNav)
      routers.push(notFoundRouter)
      console.log('routers', routers)

      resultAll.result1 = routers
      resultAll.result2 = result3
      resolve(resultAll)
    }).catch(err => {
      let status = err.response.status
      if (status == 402) {
        //跳转到企业选择
        // router.push("/user/login")
        window.location.href = "/service_provider_page/user/login";
      }
      //console.error(err.response.status)
      //reject(err)
    })
  })
}

/**
 * 动态获取权限
 * @param token
 * @returns {Promise<unknown>}
 */
export const generatorDynamicActions = (token) => {
  return new Promise((resolve, reject) => {
    loginService.getCurrentUserActions().then(res => {
      console.log('res', res)
      const actions = res.result
      resolve(actions)
    }).catch(err => {
      console.error(err)
      // reject(err)
    })
  })
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map(item => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${parent && parent.path || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      component: constantRouterComponents[item.component || item.key],

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name
      }
    }
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach(item => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}

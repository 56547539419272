<template>
  <div>
<!--  <a-popover-->
<!--    v-model="visible"-->
<!--    trigger="click"-->
<!--    placement="bottomRight"-->
<!--    overlayClassName="header-notice-wrapper"-->
<!--    :getPopupContainer="() => $refs.noticeRef.parentElement"-->
<!--    :autoAdjustOverflow="true"-->
<!--    :arrowPointAtCenter="true"-->
<!--    :overlayStyle="{ width: '300px', top: '50px' }"-->
<!--  >-->
<!--    <template slot="content">-->
<!--      <a-spin :spinning="loading">-->
<!--        <a-tabs>-->
<!--          <a-tab-pane tab="待接单" key="1">-->
<!--            <a-list size="small" bordered :data-source="data1">-->
<!--              <template #renderItem="item">-->
<!--                <a-list-item @click="gotoRoute(1)">{{ item }}</a-list-item>-->
<!--              </template>-->
<!--            </a-list>-->
<!--          </a-tab-pane>-->
<!--        </a-tabs>-->
<!--      </a-spin>-->
<!--    </template>-->
<!--    <div style="float: left;width: 115px">-->
    <span   class="header-notice"  style="padding: 0 18px" @click="gotoMessage">
      <a-badge :count="this.count"  >
<!--        <a-icon style="font-size: 16px; padding: 4px" type="bell" />-->
        <span style="font-size: 16px; padding: 4px;">待接单</span>
      </a-badge>
    </span>
<!--  </a-popover>-->
<!--    </div>-->

      <div style="width: 0px;background: red;height: 10px;float: right">
      <a-popconfirm
        placement="bottom"
        :title="text"
        :visible="visible2"
        ok-text="前往"
        cancel-text="稍后处理"
        @visibleChange="handleVisibleChange"
        @confirm="changeOrgHandleConfirm"
        @cancel="changeOrgHandleCancel"
        style="padding-top: 55px"
      >
      </a-popconfirm>
      </div>


  </div>
</template>

<script>

import Vue from "vue";
import { Modal } from 'ant-design-vue'
import {ACCESS_TOKEN} from "@/store/mutation-types";

const axios = require('axios');
 export default {
  name: 'HeaderNotice',
  data () {
    return {
      loading: false,
      visible: false,
      data1:[],
      count:0,
      visible2:false,
      confirmLoading:false,
      text:"消息内容",
      nowshowmes:{},
      timer:null
    }
  },
  methods: {
    fetchNotice () {
      let that = this
      let token = Vue.ls.get(ACCESS_TOKEN)
      // this.visible = true
      // //查询数据
      // let token = Vue.ls.get(ACCESS_TOKEN)
      // axios({
      //   url: "/service_provider/message/findList",
      //   method: 'post',
      //   headers: {
      //     'token':token,
      //     'Content-Type': 'application/json;charset=UTF-8'
      //   },
      //   data: {type:10,readed:0}
      // }).then(res =>{
      //   if(res.data!=null&&res.data.result!=null){
      //     that.data1 = []
      //     for(let i in res.data.result){
      //       that.data1.push(res.data.result[i].title)
      //     }
      //   }
      // })

      //待办数量=待接单数量+跟进事项数量
      axios({
        url: "/service_provider/message/count",
        method: 'post',
        headers: {
          'token':token,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {type:20,readed:0}
      }).then(res =>{
        if(res!=null&&res.data!=null&&res.data.result!=null){
          that.count = res.data.result
        }
        axios({
          url: "/service_provider/order/takingorder",
          method: 'post',
          headers: {
            'token':token,
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {pageNo:1,pageSize:10}
        }).then(res1 =>{
          if(res1!=null&&res1.data!=null&&res1.data.result!=null){
            that.count = that.count+res1.data.result.totalCount
          }
        })
      })

      console.info("计算总数")
      //判断未弹窗的消息，进行弹窗提示
      axios({
        url: "/service_provider/message/promptList",
        method: 'post',
        headers: {
          'token':token,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {prompt:0}
      }).then(res =>{
        if(res!=null && res.data!= null&& res.data.result!=null && res.data.result.length>0){
          that.visible2 = false
          that.text = res.data.result[0].title;
          that.nowshowmes = res.data.result[0];
          that.visible2 = true
          let popove = document.getElementsByClassName("ant-popove")

          console.info(popove)
        }
      })
    },
    changeOrgHandleCancel(){
        this.visible2 = false
    },
    changeOrgHandleConfirm(){
      if(this.nowshowmes!=null&&this.nowshowmes.type==10){
        this.$router.push({ name: 'waiting_order' })
      }else if(this.nowshowmes!=null&&this.nowshowmes.type==20){
        this.$router.push({ name: 'follow_up' })
      }else if(this.nowshowmes!=null&&this.nowshowmes.type==30){
        this.$router.push({ name: 'sys_notice' })
      }

    },
    handleVisibleChange(){

    },
    gotoMessage(){
      this.$router.push({ name: 'orderTaking' })
    }
  },
  mounted: function () {
    //定时任务刷新数量
    let that = this
    clearInterval(this.timer)
    this.timer = setInterval(that.fetchNotice(),60000)
  }
}
</script>

<style lang="css">
  .header-notice-wrapper {
    top: 50px !important;
  }
  .ant-popover-message{
        height: 80px;
        width: 200px;
  }

</style>
<style lang="less" scoped>
  .header-notice{
    display: inline-block;
    transition: all 0.3s;

    span {
      vertical-align: initial;
    }
  }
</style>

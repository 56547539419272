import api from './index'
import { axios } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return axios({
    url: '/ly_user_center/sso/login',
    method: 'post',
    data: parameter
  })
}
export function validateToken (parameter) {
  return axios({
    url: '/ly_user_center/sso/validateToken?yih_token='+parameter,
    method: 'get'
  })
}
export function changeOrg (parameter) {
  return axios({
    url: '/ly_user_center/sso/changeOrg',
    method: 'post',
    data: parameter
  })
}
export function findOrgs () {
  return axios({
    url: '/ly_user_center/sso/findOrgs',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function getSmsCaptcha (parameter) {
  return axios({
    url: api.SendSms,
    method: 'post',
    data: parameter
  })
}
export function initRandomCode (data) {
  return axios({
    url: api.initRandomCode,
    method: 'post',
    data: data
  })
}

export function getInfo () {
  return axios({
    url: '/ly_user_center/user/userInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav () {
  return axios({
    url: '/service_provider/permition/findpermitions',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function getCurrentUserActions () {
  return axios({
    url: '/service_provider/permition/findpermitions',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout () {
  return axios({
    url: '/ly_user_center/sso/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step (parameter) {
  return axios({
    url: api.twoStepCode,
    method: 'post',
    data: parameter
  })
}

<template>
  <div id="userLayout" :class="['user-layout-wrapper', device]">
    <div class="container">
      <div class="top">
        <div class="header">
          <img src="~@/assets/logo4.svg" class="logo" alt="logo">
<!--          <a href="/">-->
<!--            <img src="~@/assets/logo2.svg" class="logo" alt="logo">-->
<!--            <span class="title">冰鸽冷链</span>-->
<!--          </a>-->
        </div>
<!--        <div class="desc">-->
<!--          服务商登录-->
<!--        </div>-->
<!--        <p id="showloginerror" style="height: 8px"></p>-->
      </div>

      <route-view></route-view>

      <div class="footer">
<!--        <div class="links">-->
<!--          <a href="_self">帮助</a>-->
<!--          <a href="_self">隐私</a>-->
<!--          <a href="_self">条款</a>-->
<!--        </div>-->
        <div class="copyright">
          <a target="_blank"
             style="color:silver"
             href="https://beian.miit.gov.cn/#/Integrated/index">Copyright Beijing Yihi Information Technology Co.,LTD. All Rights Reserved. 京ICP备15065188号-3</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteView from './RouteView'
import { mixinDevice } from '@/utils/mixin'

export default {
  name: 'UserLayout',
  components: { RouteView },
  mixins: [mixinDevice],
  data () {
    return {}
  },
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="less" scoped>
  #userLayout.user-layout-wrapper {
    height: 100%;

    &.mobile {
      .container {
        .main {
          max-width: 368px;
          width: 98%;
        }
      }
    }

    .container {
      width: 100%;
      min-height: 100%;
      height: 100%;
      background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
      background-size: 100%;

      position: relative;

      a {
        text-decoration: none;
      }

      .top {
        text-align: left;


        .header {
          height: 84px;
          line-height: 44px;
          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }

          .logo {
            height: 44px;
            //vertical-align: top;
            //padding-left: 30px;
            margin-left: 30px;
            margin-top: 30px;
            //margin-right: 16px;
            border-style: none;
          }

          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, .85);
            font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }

      .main {
        //min-width: 260px;
        width: 820px;
        height: 556px;
        margin: 0 auto;
        //margin-top:20px;
      }

      .footer {
        position: absolute;
        width: 60%;
        bottom: 0;
        padding: 0 16px;
        margin: 48px 0 24px;
        text-align: left;

        .links {
          margin-bottom: 8px;
          font-size: 14px;
          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }
  }
</style>
